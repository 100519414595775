<template>
  <div class="tags">
    <h2 class="tag" :key="1" data-letters="Design">
        <span class="tag--content">Design</span>
    </h2>
    <h2 class="tag" :key="2" data-letters="Motion">
        <span class="tag--content">Motion</span>
    </h2>
    <h2 class="tag" :key="3" data-letters="Code">
        <span class="tag--content">Code</span>
    </h2>
    <h2 class="tag" :key="4" data-letters="Experience">
        <span class="tag--content">Experience</span>
    </h2>
  </div>
</template>

<script>
export default {
  name: 'tags',
  mounted() {
    const tags = document.querySelectorAll('.tag');
    const delay = 1000;
    tags.forEach((tag, i) => {
      const t = (i + 1) * delay;
      setTimeout(() => {
        tag.classList.add('mounted');
      }, t);
    });
  },
};
</script>

<style scoped lang="scss">
.tags {
    font-size: 2.2rem;
    margin-left: 2rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;

    @media (max-width: 640px) {
        font-size: 1.6rem;
        margin-left: 1rem;
        top: 45%;
    }

    .tag {
        color: #fcfcfc;
        overflow: hidden;
        position: relative;

        &--content {
            opacity: 0;
        }

        &:after {
            background: #fcfcfc;
            content: '';
            height: 100%;
            opacity: 0;
            position: absolute;
            right: 0;
            width: 100%;
            transform: translate3d(-98%,0,0);
            transition: opacity 350ms, transform 350ms 350ms;
            transition-timing-function: cubic-bezier(0.7,0,0.3,1);
        }

        &:before {
            content: attr(data-letters);
            color: #fcfcfc;
            overflow: hidden;
            position: absolute;
            z-index: 10;
            white-space: nowrap;
            width: 0;
            transition: width 350ms 350ms;
            transition-timing-function: cubic-bezier(0.7,0,0.3,1);
        }

        &.mounted {
            &:after {
                opacity: 1;
                transform: translate3d(100%,0,0);
            }

            &:before {
                width: 100%;
            }

            .tag--content {
                // opacity: 1;
            }
        }
    }
}

@keyframes blockEnter {
    0% {
        transform: translate3d(100%, 0, 0);
    }

    100% {
        transform: translate3d(-100%, 0, 0);
    }
}
</style>
