<template>
  <transition name="fadein" appear>
  <div class="contact" itemscope itemtype="http://schema.org/LocalBusiness">
    <a href="mailto:hello@motionkick.com" itemprop="email">hello@motionkick.com</a><br>
    <a href="tel:3126007883" itemprop="telephone">312.600.7883</a>
    <img class="hidden" itemprop="image" src="../assets/motionkick__logo.png"/>
    <span class="hidden" itemprop="name">MotionKick</span>
  </div>
  </transition>
</template>

<script>
export default {

};
</script>

<style scoped lang="scss">
.hidden {
    display: none;
}
.contact {
    position: absolute;
    bottom: 2rem;
    right: 2rem;
    text-align: right;
    z-index: 10;

    @media (max-width: 640px) {
        bottom: 1rem;
        right: 1rem;
    }

    a {
        // border-bottom: 1px solid transparent;
        color: #fcfcfc;
        display: inline-block;
        font-size: 1.5rem;
        text-decoration: none;
        margin-bottom: 2px;
        position: relative;
        z-index: 25;

        @media (max-width: 640px) {
            font-size: 1.2rem;
        }

        &:after {
            background-color: #0c9cd8;
            bottom: 3px;
            left: 0;
            content: '';
            height: 2px;
            position: absolute;
            width: 100%;
            z-index: 24;
            transform: scale3d(0, 1, 1);
            transform-origin: 50% 50%;
            transition: transform 250ms;
        }

        &:hover {

            &:after {
                transform: scale3d(1,1,1);
            }
            // border-bottom: 1px solid #0c9cd8;
            // text-decoration: underline;
        }
    }
}

.fadein-enter-active {
    animation: fadein 1.5s;
    animation-delay: 4.75s;
    animation-fill-mode: forwards;
    opacity: 0;
}

@keyframes fadein {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}
</style>
