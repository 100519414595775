<template>
    <img src="../assets/motionkick__logo.png" alt="MotionKick Logo" id="motionkick_logo" width="541" height="28">
</template>

<script>
export default {
  name: 'logo',
};
</script>

<style scoped lang="scss">
#motionkick_logo {
    margin-left: 2rem;
    margin-top: 2rem;
    position: relative;
    z-index: 10;
    max-width: calc(100% - 2rem);

    @media (max-width: 640px) {
        margin-left: 1rem;
        margin-top: 1rem;
    }
}
</style>
