<template>
  <div class="home">
    <logo/>
    <tags/>
    <contact/>
    <video autoplay muted loop id="bg_video" :poster="require('@/assets/mkbg.jpg')">
      <source :src="require('@/assets/mkbg_2.mp4')" type="video/mp4">
      <source :src="require('@/assets/mkbg_2.webm')" type="video/webm">
    </video>
  </div>
</template>

<script>
import Logo from '@/components/Logo.vue';
import Tags from '@/components/Tags.vue';
import Contact from '@/components/Contact.vue';

export default {
  name: 'home',
  components: {
    Logo,
    Tags,
    Contact,
  },
};
</script>

<style scoped lang="scss">
#bg_video {
  left: 50%;
  min-height: 100%;
  opacity: 0.7;
  position: fixed;
  top: 0;
  transform: translateX(-50%);
  z-index: 0;
}
</style>
